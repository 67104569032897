@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  overflow-y: scroll;
}

/* src/index.css or global.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Responsive Typography and Layout */
@media (max-width: 768px) {
  /* Reduce font sizes for all elements */
  html {
    font-size: 14px; /* Base font size reduction */
  }

  /* Responsive adjustments for common elements */
  h1 {
    font-size: 1.8rem !important; /* Force reduction */
  }

  h2 {
    font-size: 1.5rem !important;
  }

  h3 {
    font-size: 1.3rem !important;
  }

  p, div, span, a, button, input, textarea {
    font-size: 0.95rem !important;
  }

  /* Reduce padding and margins */
  .px-8 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-4 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  /* Responsive container adjustments */
  .container {
    width: 100% !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  /* Adjust max-width for readability */
  .max-w-6xl {
    max-width: 100% !important;
  }
}

/* Additional global mobile-friendly tweaks */
@media (max-width: 768px) {
  /* Ensure images are responsive */
  img {
    max-width: 100% !important;
    height: auto !important;
  }

  /* Improve form element readability */
  input, 
  textarea, 
  select, 
  button {
    font-size: 16px !important; /* Prevents zoom on iOS */
    padding: 0.5rem !important;
  }
}

/* src/index.css or global.css */
@media (max-width: 768px) {
  /* Specific targeting for blog-style h1 headings */
  .prose h1 {
    font-size: 1.5rem !important; /* Smaller on mobile */
    line-height: 1.3 !important; /* Tighter line height */
    margin-bottom: 0.75rem !important; /* Reduced bottom margin */
  }

  .prose .flex.justify-between.items-baseline h4 {
    margin-top: 0.5rem !important;
  }
}

@media (max-width: 768px) {
  .prose .flex.justify-between.items-baseline:not(.diary-entry) {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

/* src/index.css or global.css */
@media (max-width: 768px) {
  /* Reset left padding for buttons and navigation elements */
  button.text-sm.text-gray-500,
  .text-sm.text-gray-500 {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }

  /* Ensure consistency across similar elements */
  .prose .mb-6 > button,
  .prose .mb-6 > a {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }

  /* Additional fix for link navigation */
  .prose .mb-4 > a {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }
}